import { Button, Flex, HStack, Input, Text, Textarea, useToast } from '@chakra-ui/react'
import { useInView } from 'framer-motion';
import React, { useContext, useLayoutEffect, useRef } from 'react'
import { ApplicationContext } from '../context/AppContext';
import { breakpoints } from '../miscellaneous/breakpoints';

import bgImage from '../assets/ContactImage.webp'
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import emailjs from '@emailjs/browser';

export const ContactUs = () => {

    const { setActiveNav, currentLanguage } = useContext(ApplicationContext)

    const pageRef = useRef(null);
    const isPageInView = useInView(pageRef, {
        margin: '0px 0px 0px 0px'
    });

    if (isPageInView) {
        setActiveNav(3)
    }

    const location = useLocation()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    const toast = useToast()

    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const sendMessage = () => {
        setLoading(true)

        if (!firstName || !lastName || !email || !message) {
            toast({
                title: 'Error',
                description: "Please fill all the fields",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })

            setLoading(false)
            return
        }

        const params = {
            firstName,
            lastName,
            email,
            message, 
            myEmail: 'infobaseconnection@gmail.com'
        }

        emailjs.send('service_f4ck90d', 'template_t5gl81t', params, 's4i9jk7PO-pMqAN4h')
        .then(() => {
            toast({
                title: 'Sent',
                description: "We received your message.",
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })

            setFirstName('')
            setLastName('')
            setEmail('')
            setMessage('')
            setLoading(false)
        })
    }

    return (
        <Flex
            justifyContent='center'
            alignItems='center'
            ref={pageRef}
            bgImage={bgImage}
            bgPos='center'
            bgRepeat='no-repeat'
            bgSize='cover'
            overflowX='hidden'
            paddingBlock='10rem'
        >
            <Flex
                w={breakpoints}
                justifyContent='space-between'
                alignItems='center'
                flexDir={{
                    base: 'column',
                    xl: 'row'
                }}
                gap={{
                    base: '',
                    xl: '10rem'
                }}
            >
                <Flex
                    flexDir='column'
                    justifyContent='center'
                    alignItems='start'
                    w={{
                        base: '100%',
                        lg: '50%'
                    }}
                    gap='2rem'
                    flex={1.3}
                >
                    <Text
                        fontSize='2.5rem'
                        fontWeight='black'
                        color='palette.secondary'
                        paddingBlockEnd='2rem'
                        pos='relative'
                        w='100%'
                    >
                        {currentLanguage === 'English' ? 'Contact Us' : 'Kontakt'}
                    </Text>


                    <HStack
                        w='100%'
                        spacing='3rem'
                    >
                        <Input
                            w='100%'
                            variant='flushed'
                            borderColor='#949494'
                            placeholder={currentLanguage === 'English' ? 'First Name*' : 'Ime*'}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <Input
                            w='100%'
                            variant='flushed'
                            borderColor='#949494'
                            placeholder={currentLanguage === 'English' ? 'Last Name*' : 'Prezime*'}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </HStack>
                    <Input
                        w='100%'
                        variant='flushed'
                        borderColor='#949494'
                        placeholder='Email*'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Textarea
                        w='100%'
                        variant='flushed'
                        borderColor='#949494'
                        placeholder={currentLanguage === 'English' ? 'Message*' : 'Poruka*'}
                        rows={5}
                        resize='none'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button
                        w='100%'
                        bg='#45A29E'
                        color='palette.primary'
                        p='1.5rem'
                        _hover={{}}
                        onClick={sendMessage}
                        isLoading={loading}
                        loadingText='Sending...'
                    >
                        Send Message
                    </Button>
                    <Text
                        fontWeight='normal'
                        fontSize='.8em'
                        marginTop='1rem'
                        textAlign='justify'
                    >
                        {currentLanguage === 'English' ? (
                            `The Controller of your personal data provided in the contact form is Base Connection LTD, will process the data in order to answer your inquiry.`
                        ) : (
                            `Voditelj obrade vaših osobnih podataka navedenih u obrascu za kontakt je Base Connection LTD, obradit će podatke kako bismo odgovorili na vaš upit.`
                        )}
                    </Text>
                </Flex>

                <Text
                    fontWeight='normal'
                    fontSize='1.1rem'
                    marginTop={{
                        base: '3rem',
                        xl: ''
                    }}
                    textAlign='center'
                    w='100%'
                    flex={1}
                    p='2rem'
                >
                    <Text>
                        Base Connection Ltd
                    </Text>
                    <Text>
                        Ljubljanica 25
                    </Text>
                    <Text>
                        10000, Zagreb
                    </Text>
                    <Text>
                        EU, Croatia
                    </Text>
                    <Text>
                        info@baseconnect.eu
                    </Text>
                    <Text>
                        +385 99 29 29 220
                    </Text>
                </Text>
            </Flex>

        </Flex>
    )
}
