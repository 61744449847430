import { Box, Flex, Image, Text, useMediaQuery, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect, useRef } from 'react'
import { CustomButton } from '../components/CustomButton'
import { Watermark } from '../components/Watermark'
import { breakpoints } from '../miscellaneous/breakpoints'

import aboutUsMask from '../assets/LogoMask.webp'
import aboutUsImage from '../assets/AboutImage.webp'

import { motion, useInView } from 'framer-motion'

import { ApplicationContext } from '../context/AppContext'
import { container, item } from '../miscellaneous/motionVariants'

export const AboutSection = () => {

    const { setIsSectionInView, currentLanguage } = useContext(ApplicationContext)

    // SET THE REF FOR THE ELEMENT TO TRACK
    const ref = useRef(null);
    const isInView = useInView(ref, {
        margin: '0px 0px -90% 0px'
    });


    // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
    useEffect(() => {
        if (isInView) {
            setIsSectionInView(true);
        }
    }, [isInView, setIsSectionInView])

    const [isSmallerThanMd] = useMediaQuery('(min-width: 48em)')

    return (
        <Flex
            w={breakpoints}
            margin='auto'
            paddingBlock='16rem'
            justifyContent='center'
            alignItems='center'
            ref={ref}
            flexDir={{
                base: 'column',
                md: 'row'
            }}
            gap={{
                base: '6rem',
                md: '0'
            }}
        >
            <VStack
                flex={1}
                justify={{
                    md: 'start'
                }}
                align='start'
                spacing='0'

                as={motion.div}
                variants={container}
                whileInView='show'
                initial='hidden'
                viewport={{ once: true }}
            >
                <Watermark text={currentLanguage === 'English' ? 'ABOUT US' : 'O NAMA'} />
                <Text
                    fontSize='1.1rem'
                    zIndex={1}
                    w='100%'

                    as={motion.div}
                    variants={item({ x: -20 })}
                    viewport={{ once: true }}
                    textAlign='justify'
                >
                    {currentLanguage === 'English' ? (
                        'Our experience has taught us that INCEPTION is key, that is, a quality base, and our team is exactly that - Base Connection.'
                    ) : (
                        'Naše iskustvo naučilo nas je da je ključan SAM POČETAK, odnosno kvalitetna baza, a naš tim je upravo to – Base Connection.'
                    )}
                </Text>
                <Box
                    as={motion.div}
                    variants={item({ x: -20 })}
                >
                    <CustomButton path='aboutUs' text={currentLanguage === 'English' ? 'Find Out More' : 'Saznaj više'} />
                </Box>
            </VStack>
            <Box
                flex={1}
                zIndex='5'
                sx={{
                    '-webkit-mask-image': `url(${aboutUsMask})`,
                    'mask-image': `url(${aboutUsMask})`,
                    '-webkit-mask-repeat': 'no-repeat',
                    'mask-repeat': 'no-repeat',
                    '-webkit-mask-size': '75%',
                    'mask-size': '75%',
                    'mask-position': 'center'
                }}

                transform={{
                    md: 'scale(1.2)'
                }}
            >
                <Image
                    src={aboutUsImage}
                    transform='scaleX(-1)'
                    as={motion.img}
                    whileInView={{
                        scale: 1,
                        transition: {
                            duration: 1,
                            ease: 'easeOut'
                        }
                    }}
                    initial={{
                        scale: isSmallerThanMd ? 1.5 : 1,
                    }}
                    viewport={{ once: true }}
                />
            </Box>
        </Flex>
    )
}
