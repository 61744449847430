import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'

import bgImage from '../assets/bg.webp'
import { breakpoints } from '../miscellaneous/breakpoints'

import { AboutSection } from '../sections/AboutSection'
import { ApplicationContext } from '../context/AppContext'
import { motion, useInView } from 'framer-motion'
import { BusinessAreasSection } from '../sections/BusinessAreasSection'

import { useLocation } from 'react-router-dom'
import { container, item } from '../miscellaneous/motionVariants'
import { TypeAnimation } from 'react-type-animation'
import { CustomButton } from '../components/CustomButton'

export const Home = () => {

    const { setIsSectionInView, setActiveNav } = useContext(ApplicationContext)

    // SET THE REF FOR THE ELEMENT TO TRACK
    const ref = useRef(null);
    const pageRef = useRef(null)
    const isInView = useInView(ref, {
        margin: '-10% 0px 0px 0px'
    });
    const isPageInView = useInView(pageRef, {
        margin: '0px 0px 0px 0px'
    });

    // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
    useEffect(() => {
        if (isInView) {
            setIsSectionInView(false);
        }

        if (isPageInView) {
            setActiveNav(0)
        }
    }, [isInView, isPageInView, setActiveNav, setIsSectionInView])

    const location = useLocation()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    return (
        <Box
            ref={pageRef}
            overflow='hidden'
        >
            <Box
                bgImage={bgImage}
                bgPos='center'
                bgSize='cover'
                bgRepeat='no-repeat'
                ref={ref}

                as={motion.div}
                initial={{
                    opacity: .5,
                    y: -20
                }}
                animate={{
                    y: 0,
                    opacity: 1,
                    transition: {
                        duration: .3,
                        ease: 'easeOut'
                    },
                }}
                viewport={{ once: true }}
            >
                <Flex
                    justifyContent='center'
                    alignItems='start'
                    w={breakpoints}
                    margin='auto'
                    h='100vh'
                    flexDir='column'

                    as={motion.div}
                    variants={container}
                    whileInView='show'
                    initial='hidden'
                >
                    <Text
                        color='palette.primary'
                        fontWeight='bold'
                        fontSize={{
                            base: '3.5rem',
                            md: '5rem'
                        }}

                        as={motion.div}
                        variants={item({ x: -20 })}
                    >
                        We
                        <Text
                            as='span'
                            marginLeft='1rem'
                            color='palette.accent'
                        >
                            <TypeAnimation
                                sequence={[
                                    'Connect.',
                                    3000,
                                    'Develop.',
                                    3000,
                                ]}
                                wrapper="span"
                                cursor={true}
                                repeat={Infinity}
                            />
                        </Text>
                    </Text>
                    <Text
                        color='palette.primary'
                        fontSize={{
                            base: '1rem',
                            md: '1.2rem'
                        }}

                        as={motion.div}
                        variants={item({ x: -20 })}
                    >
                        We are "THAT" to make your ideas and projects come true.
                    </Text>
                    <CustomButton path='/contactUs' text='Try Us' />
                </Flex>
            </Box>
            <AboutSection />
            <BusinessAreasSection />
        </Box>
    )
}
