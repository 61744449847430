import { Flex, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import { item } from '../miscellaneous/motionVariants'

export const Watermark = ({ text }) => {
    return (
        <Flex
            justifyContent='start'
            alignItems='center'
            w='100%'
            paddingBlockEnd='3rem'
        >
            <Text
                color='#E9E9E9'
                fontWeight='black'
                fontSize={{
                    base: '6rem',
                    md: '10rem'
                }}
                pos='absolute'
                left='0'
                as={motion.p}
                w='100%'
                variants={item({ x: -10 })}
            >
                {text}
            </Text>
            <Text
                color='palette.secondary'
                fontSize='2.5rem'
                fontWeight='black'
                zIndex='1'
                as={motion.div}
                variants={item({ x: -20 })}
            >
                {text}
            </Text>
        </Flex>
    )
}

export const WatermarkV2 = ({ text }) => {
    return (
        <Flex
            justifyContent='start'
            alignItems='center'
            w='100%'
            paddingBlockEnd='3rem'
            pos='relative'
        >
            <Text
                color='#E9E9E9'
                fontWeight='black'
                fontSize={{
                    base: '6rem',
                    md: '10rem'
                }}
                pos='absolute'
                right='20rem'
                as={motion.p}
                w='100%'
                variants={item({ x: -10 })}
            >
                {text}
            </Text>
            <Text
                color='palette.secondary'
                fontSize='2.5rem'
                fontWeight='black'
                zIndex='1'
                as={motion.div}
                variants={item({ x: -20 })}
            >
                {text}
            </Text>
        </Flex>
    )
}
