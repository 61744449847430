import { Button } from '@chakra-ui/react'
import React from 'react'
import { BsArrowBarRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export const CustomButton = ({ path, text }) => {

    return (
        <Link
            to={path}
        >
            <Button
                marginTop='1.8rem'
                color='#45A29E'
                bg='transparent'
                border='1px solid'
                borderColor='#45A29E'
                fontSize='1.2rem'
                p='1.5rem'
                rightIcon={<BsArrowBarRight fontSize='1.5rem' />}
                transition='all .3s ease'
                _hover={{
                    bg: '#45A29E',
                    color: 'palette.primary'
                }}
            >
                { text }
            </Button>

        </Link>
    )
}
