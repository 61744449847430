import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react'
import { motion, useInView } from 'framer-motion';
import React, { useContext, useLayoutEffect, useRef } from 'react'
import { ApplicationContext } from '../context/AppContext';
import { breakpoints } from '../miscellaneous/breakpoints';

import aboutUsMask from '../assets/LogoMask.webp'
import aboutUsImage from '../assets/AboutImage.webp'
import { List } from '../components/List';
import { useLocation } from 'react-router-dom';

export const AboutUs = () => {

    const { setActiveNav, currentLanguage } = useContext(ApplicationContext)

    const pageRef = useRef(null);
    const isPageInView = useInView(pageRef, {
        margin: '0px 0px 0px 0px'
    });

    if (isPageInView) {
        setActiveNav(1)
    }

    const location = useLocation()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    return (
        <Flex
            w='100%'
            paddingBlock='15rem'
            ref={pageRef}
            flexDir='column'
            gap='10rem'
            overflowX='hidden'
        >
            <Flex
                w={breakpoints}
                margin='auto'
                flexDir='column'
                gap='8rem'
            >
                <Flex
                    w='100%'
                    justifyContent='space-between'
                    alignItems='center'
                    flexDir={{
                        base: 'column',
                        md: 'row'
                    }}
                    gap={{
                        base: '5rem',
                        md: '0'
                    }}
                >
                    <VStack
                        flex={1}
                        justify='start'
                        align='start'
                        spacing='2rem'

                        as={motion.p}
                        initial={{
                            x: -20,
                            opacity: 0
                        }}
                        whileInView={{
                            x: 0,
                            opacity: 1,
                            transition: {
                                duration: .5
                            }
                        }}
                        viewport={{ once: true }}
                    >
                        <Text
                            fontWeight='black'
                            fontSize='2.6rem'
                            color='palette.secondary'
                            pos='relative'
                            w='100%'
                        >
                            {currentLanguage === 'English' ? 'ABOUT US' : 'O NAMA'}

                            <Text
                                pos='absolute'
                                color='#E6E6E6'
                                fontWeight='black'
                                fontSize='7rem'
                                top='-5rem'
                                left='-8rem'
                                zIndex={-1}
                                w='100%'
                            >
                                {currentLanguage === 'English' ? 'ABOUT US' : 'O NAMA'}
                            </Text>
                        </Text>
                        <Text
                            fontSize='1.1rem'
                            zIndex={1}
                            textAlign='justify'
                        >
                            {currentLanguage === 'English' ?
                                'You have an idea and enthusiasm. You have a vision and mission of the company/project. You even have the necessary funds or at least part of it. Great to start with! But still not enough. You have one unanswered, but extremely important question left - how to achieve everything imagined/planned?'
                                :
                                "Vi imate ideju i entuzijazam. Imate viziju i misiju tvrtke/projekta. Raspolažete čak i s potrebnim novcem ili barem dijelom novca. Izvrsno za početak! No, ipak nedovoljno. Ostalo Vam je jedno neodgovoreno, ali izuzetno značajno pitanje – kako ostvariti sve zamišljeno/planirano?"
                            }
                        </Text>
                    </VStack>
                    <Box
                        flex={1}
                        zIndex='5'
                        sx={{
                            '-webkit-mask-image': `url(${aboutUsMask})`,
                            'mask-image': `url(${aboutUsMask})`,
                            '-webkit-mask-repeat': 'no-repeat',
                            'mask-repeat': 'no-repeat',
                            '-webkit-mask-size': '75%',
                            'mask-size': '75%',
                            'mask-position': 'center'
                        }}

                        transform='scale(1.2)'
                    >
                        <Image
                            src={aboutUsImage}
                            transform='scaleX(-1)'
                            as={motion.img}
                            whileInView={{
                                scale: 1,
                                transition: {
                                    duration: 1,
                                    ease: 'easeOut'
                                }
                            }}
                            initial={{
                                scale: 1.5,
                            }}
                            viewport={{ once: true }}
                        />
                    </Box>
                </Flex>

                <Flex
                    w='100%'
                    gap='5rem'
                    fontSize='1.1rem'
                    flexDir={{
                        base: 'column',
                        md: 'row'
                    }}
                >
                    <Text
                        flex={1}

                        as={motion.p}
                        initial={{
                            x: -20,
                            opacity: 0
                        }}
                        whileInView={{
                            x: 0,
                            opacity: 1,
                            transition: {
                                duration: .5
                            }
                        }}
                        viewport={{ once: true }}
                        textAlign='justify'
                    >
                        {currentLanguage === 'English' ? (
                            'Our experience has taught us that INCEPTION is key, that is, a quality base, and our team is exactly that - Base Connection. We are the ones who shape, develop and realize your idea, for you and with you. We deal with the development of products and projects according to the system "from idea to realization" or participate in the realization of one or more segments of development, all depending on your needs and requirements. All of the above includes design, graphic design, technical documentation and sample making, certification, making the final product ready for distribution, as well as organizing logistics and finding new markets and customers.'

                        ) : (
                            'Naše iskustvo naučilo nas je da je ključan SAM POČETAK, odnosno kvalitetna baza, a naš tim je upravo to – Base Connection. Mi smo oni koji Vašu ideju oblikuju, razvijaju i realiziraju je, za Vas i sa Vama. Bavimo se razvojem proizvoda i projekata po sistemu „od ideje do realizacije“ ili sudjelujemo u realizaciji  jednog ili više segmenata razvoja, sve ovisno o Vašim potrebama i zahtjevima. Sve navedeno uključuje dizajn, grafički dizajn, tehničku dokumentaciju i izradu uzoraka, certifikaciju, izradu finalnog proizvoda spremnog za distribuciju, kao i organizaciju logistike te pronalazak novih tržišta i kupaca.'
                        )}
                    </Text>
                    <Text
                        flex={1}
                        pos='relative'
                        w='100%'

                        as={motion.p}
                        initial={{
                            x: 20,
                            opacity: 0
                        }}
                        whileInView={{
                            x: 0,
                            opacity: 1,
                            transition: {
                                duration: .5
                            }
                        }}
                        viewport={{ once: true }}
                        textAlign='justify'
                    >
                        {currentLanguage === 'English' ? (
                            `Many years of experience in creation, development and production and many business contacts have put us in a position where we can really provide a complete and individualized service completely adaptable to your intentions. We are able to professionally, humorously and vividly implement your idea and vision in all the necessary materials that will impress your investors. In this way, the product development process becomes a process that both you and we enjoy, because acting as a team, we successfully overcome obstacles and achieve excellent results.`
                        ) : (
                            `Dugogodišnje iskustvo u stvaranju, razvoju i proizvodnji te mnogi poslovni kontakti doveli su nas u poziciju da zaista možemo pružiti kompletnu i individualiziranu uslugu potpuno prilagodljivu Vašim intencijama. U mogućnosti smo profesionalno, duhovito i živopisno implementirati Vašu ideju i viziju u sve potrebne materijale koji će impresionirati Vaše investitore. Na taj način proces razvoja proizvoda postaje proces u kojem uživate i Vi i mi jer djelujući kao tim s uspjehom prelazimo preko prepreka te dolazimo do izvrsnih rezultata.`
                        )}

                        <Text
                            pos='absolute'
                            color='#E6E6E6'
                            fontWeight='black'
                            fontSize='7rem'
                            top='-5rem'
                            left='0'
                            zIndex={-1}
                            w='100vw'
                        >
                            EXPERIENCE
                        </Text>
                    </Text>
                </Flex>

            </Flex>
            <Flex
                w='100%'
                bg='#E9E9E9'
                pos='relative'
                justifyContent='center'
                paddingBlock={{
                    base: '13rem 25rem',
                    md: '5rem 10rem'
                }}
            >
                <Flex
                    p='2rem'
                    borderRadius='.5rem'
                    bg='#F0F0F0'
                    boxShadow='2px 2px 20px rgba(0, 0, 0, .2)'
                    fontSize='1.1rem'
                    pos='absolute'
                    w={{ ...breakpoints, '2xl': '65%' }}
                    top='-4rem'
                    margin='auto'
                    as={motion.div}
                    initial={{
                        y: -20,
                        opacity: 0
                    }}
                    whileInView={{
                        x: 0,
                        opacity: 1,
                        transition: {
                            duration: .5
                        }
                    }}
                    viewport={{ once: true }}

                >
                    <Text
                        textAlign='justify'
                    >
                        {currentLanguage === 'English' ? (
                            'From our past experience in working with clients, we have learned that for every idea/product, the client always has 10 key wishes, behind which there are also 10 disciplines and knowledge that must be mastered in order for the product/project to be successfully launched. Are these also your 10 wishes or did we miss something?'

                        ) : (
                            'Iz dosadašnjeg iskustva u radu s Klijentima, razaznali smo da za svaku ideju/proizvod Klijent uvijek ima 10 ključnih želja, iza kojih stoji i 10 disciplina i znanja kojima treba ovladati da bi se proizvod/projekt uspješno pokrenuo. Je li ovo i Vaših 10 želja ili smo nešto propustili?'
                        )}
                    </Text>
                </Flex>
                <Flex
                    p='2rem'
                    borderRadius='.5rem'
                    bg='#F0F0F0'
                    boxShadow='2px 2px 20px rgba(0, 0, 0, .2)'
                    fontSize='1.1rem'
                    pos='absolute'
                    w={{ ...breakpoints, '2xl': '65%' }}
                    bottom='-6rem'
                    margin='auto'
                    flexDir='column'
                    gap='1rem'

                    as={motion.div}
                    initial={{
                        y: -20,
                        opacity: 0
                    }}
                    whileInView={{
                        x: 0,
                        opacity: 1,
                        transition: {
                            duration: .5
                        }
                    }}
                    viewport={{ once: true }}
                >
                    <Text
                    >
                        {currentLanguage === 'English' ? (
                            'For each of the mentioned stages, in our team you will have a person who, with his "touch", will transform basic information and simple inputs into top-quality final materials that lead to the desired result - profit from the business venture in the shortest possible time and for the longest possible period.'

                        ) : (
                            'Za svaku od navedenih faza, u našem timu imat ćete osobu koja svojim „dodirom“ pretvara bazne informacije i jednostavne inpute u vrhunske finalne materijale koji vode do željenog rezultata - zarade na poslovnom poduhvatu u što kraćem roku i na što duži period.'
                        )}

                    </Text>
                    <Text>
                        {currentLanguage === 'English' ? (
                            'And of course, when we jointly reach the goal we set for ourselves at the beginning, we are still here for you, either at your call, or as permanent external support and a well-established team for a new business venture.'

                        ) : (
                            'I naravno, kada zajednički dođemo do onog cilja koji smo si zacrtali na početku, i nadalje smo tu za Vas, ili na Vaš poziv, ili kao trajna vanjska podrška i uhodani tim za novi poslovni poduhvat.'
                        )}
                    </Text>
                </Flex>

                <List />
            </Flex>
        </Flex>
    )
}
