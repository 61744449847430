import { Box, Button, Flex, IconButton, Image, Menu, MenuButton, MenuDivider, MenuItem, MenuList, useMediaQuery } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { ApplicationContext } from '../context/AppContext'

import Logo from '../assets/Logo.webp'
import { motion } from 'framer-motion'
import { container, item } from '../miscellaneous/motionVariants'

import { Link, NavLink } from 'react-router-dom'
import { breakpoints } from '../miscellaneous/breakpoints'

import { HiMenuAlt3 } from 'react-icons/hi'
import { AiFillCaretDown } from 'react-icons/ai'

import English from '../assets/America.webp'
import Croatia from '../assets/Crotia.webp'

export const Navbar = () => {

    // GET THE STATES FROM THE CONTEXT
    const { scrolled, activeNav, setActiveNav, isSectionInView, setIsSectionInView, currentLanguage, setCurrentLanguage } = useContext(ApplicationContext)

    // TRACK SCREEN SIZE TO ADJUST THE NAV APPEARANCE
    const [isSmallerThan850] = useMediaQuery('(max-width: 850px)')

    // INITIALIZE THE ARRAY OF NAV LINKS TO BE MAPPED
    const navLinks = [
        { path: '/', label: 'Home' },
        { path: '/aboutUs', label: currentLanguage === 'English' ? 'About Us' : 'O Nama' },
        { path: '/businessAreas', label: currentLanguage === 'English' ? 'Business Areas' : 'Poslovna Područja ' },
        { path: '/contactUs', label: currentLanguage === 'English' ? 'Contact Us' : 'Kontakt' },
    ]

    const activeNavStyle = {
        color: activeNav !== 0 ? '#00968F' : 'palette.accent',
        fontWeight: 'bold',
    }

    return (
        <Box
            w='100vw'
            transition='all .3s ease'
            h={scrolled ? '6rem' : '7rem'}
            pos='fixed'
            zIndex='100'
            backdropFilter={scrolled && 'blur(15px)'}
            boxShadow={scrolled && '0 0 10px rgba(0, 0, 0, .2)'}
            bg={isSectionInView && activeNav === 0 && 'rgba(0, 0, 0, .9)'}
        >
            <Flex
                w={breakpoints}
                h='100%'
                margin='auto'
                alignItems='center'
                justifyContent='space-between'

                as={motion.div}
                variants={container}
                animate='show'
                initial='hidden'
            >
                {/* LOGO */}
                <NavLink
                    to='/'
                    onClick={() => setActiveNav(0)}
                >
                    <Image
                        transition='all .3s ease'
                        w={scrolled ? '11.9rem' : '12rem'}
                        src={Logo}


                        as={motion.img}
                        variants={item({ y: -20 })}
                    />
                </NavLink>

                {/* NAVIGATION LINKS */}
                {isSmallerThan850 ? (
                    <Box>
                        <Menu>
                            <MenuButton
                                color={activeNav === 0 ? 'white' : 'black'}
                                as={IconButton}
                                icon={<HiMenuAlt3 />}
                                variant='outline'
                                borderColor={activeNav === 0 ? 'white' : 'black'}

                                _active={{}}
                                _hover={{
                                    bg: activeNav === 0 ? 'white' : 'black',
                                    color: activeNav === 0 ? 'black' : 'white'
                                }}
                            />
                            <MenuList>
                                <Link to='/'>
                                    <MenuItem>
                                        Home
                                    </MenuItem>
                                </Link>
                                <Link to='/aboutUs'>
                                    <MenuItem>
                                        About Us
                                    </MenuItem>
                                </Link>
                                <Link to='/businessAreas'>
                                    <MenuItem >
                                        Business Areas
                                    </MenuItem>
                                </Link>
                                <Link to='/contactUs'>
                                    <MenuItem >
                                        Contact Us
                                    </MenuItem>
                                </Link>

                                <MenuDivider />

                                <MenuItem
                                    icon={
                                        <Image
                                            src={English}
                                            w='1rem'
                                        />
                                    }
                                    onClick={() => setCurrentLanguage('English')}
                                >
                                    English
                                </MenuItem>
                                <MenuItem
                                    icon={
                                        <Image
                                            src={Croatia}
                                            w='1rem'
                                        />
                                    }
                                    onClick={() => setCurrentLanguage('Croatia')}
                                >
                                    Croatia
                                </MenuItem>
                            </MenuList>

                        </Menu>
                    </Box>
                ) : (
                    <>
                        <Flex
                            alignItems='center'
                            justifyContent='center'
                            gap='3rem'
                        >
                            {navLinks.map((nav, index) => {
                                return (
                                    <Box
                                        display='flex'
                                        justifyContent='center'
                                        alignItems='center'
                                        key={index}
                                        transition='all .2s ease'
                                        fontSize={scrolled && '.99rem'}
                                        pos='relative'
                                        fontWeight='normal'
                                        color={activeNav !== 0 ? 'palette.secondary' : 'palette.primary'}
                                        {...activeNav === index && activeNavStyle}
                                        _hover={{
                                            color: activeNav !== 0 ? '#00968F' : 'palette.accent'
                                        }}
                                        onClick={() => {
                                            setIsSectionInView(false)
                                        }}

                                        as={motion.div}
                                        variants={item({ y: -20 })}
                                    >
                                        <NavLink to={nav.path}>
                                            {nav.label}
                                        </NavLink>
                                    </Box>
                                )
                            })}

                            <Menu>
                                <MenuButton
                                    bg='transparent'
                                    color='palette.accent'
                                    border='1px solid'
                                    _hover={{
                                        bg: 'palette.accent',
                                        color: 'white',
                                        borderColor: 'palette.accent'
                                    }}
                                    _active={{
                                        bg: 'palette.accent',
                                        color: 'white',
                                        borderColor: 'palette.accent'
                                    }}
                                    fontSize='.9rem'
                                    fontWeight='semibold'
                                    p='0 .5rem'
                                    as={Button}
                                    leftIcon={currentLanguage === 'English' ? (
                                        <Image
                                            src={English}
                                            w='1rem'
                                        />
                                    ) : (
                                        <Image
                                            src={Croatia}
                                            w='1rem'
                                        />
                                    )}
                                    rightIcon={<AiFillCaretDown/>}
                                >
                                    {currentLanguage}
                                </MenuButton>
                                <MenuList>
                                    <MenuItem
                                        icon={
                                            <Image
                                                src={English}
                                                w='1rem'
                                            />
                                        }
                                        fontSize='.9rem'
                                        onClick={() => setCurrentLanguage('English')}
                                    >
                                        English
                                    </MenuItem>
                                    <MenuItem
                                        icon={
                                            <Image
                                                src={Croatia}
                                                w='1rem'
                                            />
                                        }
                                        fontSize='.9rem'
                                        onClick={() => setCurrentLanguage('Croatia')}
                                    >
                                        Croatia
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Flex>
                    </>
                )}
            </Flex>
        </Box>
    )
}
