// ANIMATION VARIANTS
export const container = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.3
        }
    }
}

export const item = ({x, y}) => {
    return {
        hidden: { opacity: 0, x, y },
        show: { 
            opacity: 1, 
            x: 0,
            y: 0,
            transition: {
                duration: .3,
                ease: 'easeOut'
            }
        }
    }
}