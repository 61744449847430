import { Flex, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import { useContext } from 'react'
import { ApplicationContext } from '../context/AppContext'
import { breakpoints } from '../miscellaneous/breakpoints'

export const List = () => {

    const { currentLanguage } = useContext(ApplicationContext)

    const checkIfEven = (number) => {
        if (number % 2 === 0) {
            return true
        } else {
            return false
        }
    }

    const wishList = [
        { number: '1', text: currentLanguage === 'English' ? 'I want a new product better or different from the existing ones!' : 'Želim novi proizvod bolji ili drugačiji od postojećih!' },
        { number: '2', text: currentLanguage === 'English' ? 'I want to produce it at the lowest possible cost but while maintaining the desired quality!' : 'Želim ga proizvesti uz što manji trošak ali uz zadržavanje željene kvalitete! ' },
        { number: '3', text: currentLanguage === 'English' ? 'I want to market!' : 'Želim ga plasirati na tržište (i dobro živjeti od takvog plasiranja)!' },
        { number: '4', text: currentLanguage === 'English' ? 'I want to find an adequate partner for my project!' : 'Želim naći adekvatnog partnera za svoj projekt!' },
        { number: '5', text: currentLanguage === 'English' ? 'I want favorable and long-term financial resources available for the project!' : 'Želim povoljna i dugoročno raspoloživa financijska sredstva za projekt!' },
        { number: '6', text: currentLanguage === 'English' ? 'I want a financial, business, sales and/or marketing plan!' : 'Želim financijski, poslovni, prodajni i/ili marketinški plan!' },
        { number: '7', text: currentLanguage === 'English' ? 'I want someone who will "push" me if I get tired during the development of the project!' : 'Želim nekoga tko će me „pogurati“ ako dođe do zamora tijekom razvoja projekta!' },
        { number: '8', text: currentLanguage === 'English' ? 'I want an attractive design for my product (so that the competition asks "How did I not think of that?")' : 'Želim primamljiv dizajn za svoj proizvod (da se konkurencija pita „Kako se ja toga nisam dosjetio?“)' },
        { number: '9', text: currentLanguage === 'English' ? 'I want to enjoy the whole development process!' : 'Želim uživati u čitavom procesu razvoja!' },
        { number: '10', text: currentLanguage === 'English' ? 'I want to develop another new product because the experience of developing this one was exciting and great!' : 'Želim razviti još jedan novi proizvod jer je iskustvo u razvoju ovoga bilo uzbudljivo i izvrsno!' },
    ]

    return (
        <Flex
            w={{ ...breakpoints, '2xl': '65%' }}
            margin='auto'
            flexDir='column'
        >
            {wishList.map((list) => {
                return (
                    <Flex
                        alignItems='center'
                        justifyContent={checkIfEven(list.number) ? 'end' : 'start'}
                        gap='2rem'
                        key={list.number}
                        flexDir={checkIfEven(list.number) ? 'row-reverse' : 'row'}

                        as={motion.div}
                        initial={{
                            x: checkIfEven(list.number) ? 20 : -20,
                            opacity: 0
                        }}
                        whileInView={{
                            x: 0,
                            opacity: 1,
                            transition: {
                                duration: .5
                            }
                        }}
                        viewport={{ once: true }}

                    >
                        <Text
                            fontSize={{
                                base: '4rem',
                                md: '5rem'
                            }}
                            fontWeight='black'
                            color='#D4D4D4'
                        >
                            {list.number}
                        </Text>
                        <Text
                            fontSize={{
                                base: '1rem',
                                md: '1.2rem'
                            }}
                            color='#8A8A8A'
                            fontWeight='semibold'
                            w='75%'
                            textAlign={checkIfEven(list.number) ? 'end' : 'start'}
                        >
                            {list.text}
                        </Text>
                    </Flex>
                )
            })}
        </Flex>
    )
}
