
import { extendTheme } from "@chakra-ui/react"

const colors = {
    palette: {
        primary: '#F4F4F4',
        secondary: '#0B0C10',
        tertiary: '#EDEDED',
        accent: '#32C0BA'
    }
}

const fonts = {
    body: `'Source Sans Pro', sans-serif`
}

const styles = {
    global: {
        html: {
            scrollBehavior: 'smooth',
            overflowX: 'hidden', 
        },
        body: {
            bg: 'palette.primary',
            color: 'palette.secondary',
        },
        _placeholder: {
            color: 'rgba(38, 50, 56, .6)',
            fontSize: '.9rem'
        },
    }
}

export const theme = extendTheme({ colors, fonts, styles });