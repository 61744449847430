import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { Navbar } from './components/Navbar'
import { theme } from './miscellaneous/theme'
import { Home } from './pages/Home'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AboutUs } from './pages/AboutUs'
import { BusinessAreas } from './pages/BusinessAreas'
import { ContactUs } from './pages/ContactUs'
import { Footer } from './components/Footer'

import "@fontsource/source-sans-pro/400.css"
import "@fontsource/source-sans-pro/600.css"
import "@fontsource/source-sans-pro/700.css"
import "@fontsource/source-sans-pro/900.css"

export const App = () => {

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter basename='/'>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutUs' element={<AboutUs />} />
          <Route path='/businessAreas' element={<BusinessAreas />} />
          <Route path='/contactUs' element={<ContactUs />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ChakraProvider>
  )
}
