import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { breakpoints } from '../miscellaneous/breakpoints'

import { FaInstagramSquare } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import logo from '../assets/Logo.webp'
import { ApplicationContext } from '../context/AppContext'

export const Footer = () => {

    const { currentLanguage } = useContext(ApplicationContext)

    return (
        <Box
            w='100%'
            h='12rem'
            bg='#E6E6E6'
        >
            <Flex
                w={breakpoints}
                margin='auto'
                justifyContent='center'
                alignItems='center'
                paddingBlock='2rem'
                flexDir='column'
                gap='1rem'
            >
                <Link
                    to='/'
                >
                    <Image
                        src={logo}
                        w='10rem'
                        paddingBlockEnd='1.8rem'
                    />
                </Link>
                <HStack
                    fontSize='1rem'
                    spacing='1.5rem'
                    color='#1E1E1E'
                >
                    <Box
                        transition='all .3s ease'
                        _hover={{
                            color: '#45A29E'
                        }}
                    >
                        <FaInstagramSquare />
                    </Box>
                    <Box
                        transition='all .3s ease'
                        _hover={{
                            color: '#45A29E'
                        }}
                    >
                        <FaLinkedin />
                    </Box>
                    <Box
                        transition='all .3s ease'
                        _hover={{
                            color: '#45A29E'
                        }}
                    >
                        <FaFacebook />
                    </Box>
                </HStack>
                <HStack
                    spacing='1rem'
                >
                    <Link to='/'>
                        <Text
                            fontSize='.9rem'
                            transition='all .3s ease'
                            _hover={{
                                color: '#45A29E'
                            }}
                        >
                            Home
                        </Text>
                    </Link>
                    <Link to='/aboutUs'>
                        <Text
                            fontSize='.9rem'
                            transition='all .3s ease'
                            _hover={{
                                color: '#45A29E'
                            }}
                        >
                            {currentLanguage === 'English' ? 'About' : 'O NAMA'}
                        </Text>
                    </Link>
                    <Link to='/businessAreas'>
                        <Text
                            fontSize='.9rem'
                            transition='all .3s ease'
                            _hover={{
                                color: '#45A29E'
                            }}
                        >
                            {currentLanguage === 'English' ? 'Business Areas' : 'POSLOVNA PODRUČJA'}
                        </Text>
                    </Link>
                    <Link to='/contactUs'>
                        <Text
                            fontSize='.9rem'
                            transition='all .3s ease'
                            _hover={{
                                color: '#45A29E'
                            }}
                        >
                            {currentLanguage === 'English' ? 'Contact' : 'KONTAKT'}
                        </Text>
                    </Link>
                </HStack>
            </Flex>
        </Box>
    )
}
