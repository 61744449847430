import { Flex, Image, Text, VStack } from '@chakra-ui/react'
import { motion, useInView } from 'framer-motion';
import React, { useContext, useLayoutEffect, useRef } from 'react'
import { ApplicationContext } from '../context/AppContext';
import { breakpoints } from '../miscellaneous/breakpoints';

import ProductDevelopment from '../assets/product development1.webp'
import ProjectDevelopment from '../assets/project development1.webp'
import StrategyFormation from '../assets/STRATEGY FORMATION1.webp'
import RiskManagement from '../assets/risk management1.webp'
import { useLocation } from 'react-router-dom';

export const BusinessAreas = () => {

    const { setActiveNav, currentLanguage } = useContext(ApplicationContext)

    const pageRef = useRef(null);
    const isPageInView = useInView(pageRef, {
        margin: '0px 0px 0px 0px'
    });

    if (isPageInView) {
        setActiveNav(2)
    }

    const location = useLocation()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    return (
        <Flex
            w='100%'
            paddingBlock='12rem'
            ref={pageRef}
            flexDir='column'
            gap='10rem'
            overflowX='hidden'
        >
            <Flex
                w={breakpoints}
                margin='auto'
                flexDir='column'
                gap='2rem'
                alignItems='center'
            >
                <Text
                    textAlign='center'
                    color='palette.secondary'
                    fontWeight='black'
                    fontSize='2.5rem'
                    paddingBlockEnd='4rem'
                    pos='relative'
                    w='100%'

                    as={motion.div}
                    initial={{
                        y: -20,
                        opacity: 0
                    }}
                    animate={{
                        y: 0,
                        opacity: 1
                    }}
                    viewport={{ once: true }}
                >
                    {currentLanguage === 'English' ? 'Business Areas' : 'POSLOVNA PODRUČJA'}
                    <Text
                        pos='absolute'
                        color='#E6E6E6'
                        fontWeight='bold'
                        fontSize={{
                            base: '5rem',
                            md: '7rem'
                        }}
                        top='-5rem'
                        right='50%'
                        transform='translate(50%)'
                        zIndex={-1}
                        w='100%'
                    >
                        {currentLanguage === 'English' ? 'Business Areas' : 'POSLOVNA PODRUČJA'}
                    </Text>
                </Text>

                <Flex
                    maxW='65rem'
                    p={{
                        base: '1rem',
                        md: '2rem'
                    }}
                    gap={{
                        base: '2rem',
                        md: '0'
                    }}
                    flexDir={{
                        base: 'column',
                        md: 'row'
                    }}
                    bg='#F0F0F0'
                    boxShadow='1px 2px 20px rgba(0, 0, 0, .2)'
                    borderRadius='.5rem'

                    as={motion.div}
                    initial={{
                        x: -20,
                        opacity: 0
                    }}
                    whileInView={{
                        x: 0,
                        opacity: 1,
                        transition: {
                            duration: .3
                        }
                    }}
                    viewport={{ once: true }}
                >
                    <Flex
                        flex={1}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Image src={ProductDevelopment} w='35%' />
                    </Flex>
                    <VStack
                        flex={1}
                        spacing='.1rem'
                        align='start'
                    >
                        <Text
                            fontWeight='bold'
                            color='#45A29E'
                            fontSize='2rem'
                            w='100%'
                            textAlign={{
                                base: 'center',
                                md: 'start'
                            }}
                        >
                            {currentLanguage === 'English' ? 'Product Development' : 'RAZVOJ PROIZVODA'}
                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            paddingBlockStart='1.5rem'
                            textAlign='justify'
                        >
                            {currentLanguage === 'English' ? 'You need a product, but you lack "a few little things" - design, prototypes, first samples, production, certificates, logistics... We are here for you - from the smallest to much more complex products, because products are like children: "A small child is a small concern, a big child is a big concern". Product development and new product management - is a series of steps that includes the conceptualization, design, development, and marketing of newly created or newly modified goods or services. Product development, in which you definitely need support, includes the entire journey of the product - from the initial idea to market release.' : 'Potreban vam je proizvod, a nedostaje vam "par sitnica" - dizajn, prototipovi, prvi uzorci, proizvodnja, certifikati, logistika... Tu smo za vas - od najmanjih do puno složenijih proizvoda, jer proizvodi su kao djeca : "Malo dijete je mala briga, veliko dijete je velika briga". Razvoj proizvoda i upravljanje novim proizvodima - niz je koraka koji uključuje konceptualizaciju, dizajn, razvoj i marketing novostvorenih ili novoizmijenjenih proizvoda ili usluga. Razvoj proizvoda, u čemu svakako trebate podršku, uključuje cijeli put proizvoda – od inicijalne ideje do izlaska na tržište.'}
                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            fontWeight='bold'
                            paddingBlock='1.5rem'
                        >
                            {currentLanguage === 'English' ? 'What does the product development process look like?' : 'Kako izgleda proces razvoja proizvoda?'}

                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            fontWeight='semibold'
                        >
                            {currentLanguage === 'English' ? "-	Let's start with the vision of the product and the common goal for our product" : '- Krenimo od vizije proizvoda i zajedničkog cilja za naš proizvod'}
                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            fontWeight='semibold'
                        >
                            {currentLanguage === 'English' ? "-	Defining the primary goals for the product and identifying potential areas" : '- Definiranje primarnih ciljeva za proizvod i identificiranje potencijalnih područja'}
                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            fontWeight='semibold'
                        >
                            {currentLanguage === 'English' ? " - Creativity in product design - Product conceptualization" : '- Kreativnost u dizajnu proizvoda - Konceptualizacija proizvoda'}

                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            fontWeight='semibold'
                        >
                            {currentLanguage === 'English' ? "- Creation of the first samples and prototypes (sustainable solution)" : '- Izrada prvih uzoraka i prototipova (održivo rješenje)'}
                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            fontWeight='semibold'
                        >
                            {currentLanguage === 'English' ? "-	Arrangement of regular production and product certification" : '- Organizacija proizvodnje i certificiranje proizvoda'}

                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            fontWeight='semibold'
                        >
                            {currentLanguage === 'English' ? "- Determine the priorities of marketing channels and messages and start testing the sensitivity of prices and packaging" : '- Određivanje prioriteta marketinških kanala i poruka te testiranje osjetljivosti cijena i pakiranja'}

                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            fontWeight='semibold'
                        >
                            {currentLanguage === 'English' ? "- Logistics planning" : '- Planiranje logistike'}

                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            fontWeight='semibold'
                        >
                            {currentLanguage === 'English' ? "- Product launch strategy and communication strategy" : '- Strategija lansiranja proizvoda i komunikacijska strategija'}
                        </Text>
                    </VStack>
                </Flex>

                <Flex
                    maxW='65rem'
                    bg='#F0F0F0'
                    boxShadow='1px 2px 20px rgba(0, 0, 0, .2)'
                    borderRadius='.5rem'

                    p={{
                        base: '1rem',
                        md: '2rem'
                    }}
                    gap={{
                        base: '2rem',
                        md: '0'
                    }}
                    flexDir={{
                        base: 'column-reverse',
                        md: 'row'
                    }}

                    as={motion.div}
                    initial={{
                        x: 20,
                        opacity: 0
                    }}
                    whileInView={{
                        x: 0,
                        opacity: 1,
                        transition: {
                            duration: .3
                        }
                    }}
                    viewport={{ once: true }}
                >
                    <VStack
                        flex={1}
                        spacing='1.5rem'
                        align='start'
                    >
                        <Text
                            fontWeight='bold'
                            color='#45A29E'
                            fontSize='2rem'
                            w='100%'
                            textAlign={{
                                base: 'center',
                                md: 'start'
                            }}
                        >
                            {currentLanguage === 'English' ? 'Project Development' : 'RAZVOJ PROJEKTA'}
                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            textAlign='justify'
                        >
                            {currentLanguage === 'English' ? (
                                `You don't dare to manage the project yourself or you don't have time due to existing obligations? You have not started an investment because you are not sure which investor or partner would be optimal for you? We offer our services in the mentioned segment to entrepreneurs, domestic and foreign investors, operators, but also to existing intermediaries and advisors, because even they may not cover certain areas and need a partner for the same.
                                We love the start-up mindset. Such a way of thinking brings passion into the development process, because during the same it leads to new ideas and possibilities that were not even imagined at the very beginning, that is, as the good old proverb says: "You go into the forest to chase the rabbit, and you chase the wolf away". Maybe in the end it will also be the Wolf of Wall Street, you never know, you just need to start boldly and creatively.`

                            ) : (
                                `Ne usuđujete se sami voditi projekt ili nemate vremena uslijed postojećih obveza? Niste započeli s investicijom jer niste sigurni koji investitor ili partner bi bio optimalan za Vas? Svoje usluge u navedenom segmentu nudimo poduzetnicima, domaćim i stranim investitorima, operaterima ali i postojećim  posrednicima i savjetnicima, jer niti oni možda ne pokrivaju pojedina područja i potreban im je partner za isto. 

                                Volimo start-up način razmišljanja. Takav način razmišljanja unosi strast u proces razvoja, jer tijekom istoga dovodi do novih ideja i mogućnosti koje nisu bile ni u primisli na samom početku, odnosno kako kaže dobra stara poslovica: „Kreneš u šumu tjerati zeca, a istjeraš vuka“. Možda to na kraju bude i Vuk s Wall Streeta, nikada se ne zna, samo treba krenuti hrabro i kreativno.
                                
                                `
                            )}
                        </Text>
                    </VStack>
                    <Flex
                        flex={1}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Image src={ProjectDevelopment} w='35%' />
                    </Flex>
                </Flex>

                <Flex
                    maxW='65rem'
                    bg='#F0F0F0'
                    boxShadow='1px 2px 20px rgba(0, 0, 0, .2)'
                    borderRadius='.5rem'

                    p={{
                        base: '1rem',
                        md: '2rem'
                    }}
                    gap={{
                        base: '2rem',
                        md: '0'
                    }}
                    flexDir={{
                        base: 'column-reverse',
                        md: 'row-reverse'
                    }}

                    as={motion.div}
                    initial={{
                        x: -20,
                        opacity: 0
                    }}
                    whileInView={{
                        x: 0,
                        opacity: 1,
                        transition: {
                            duration: .3
                        }
                    }}
                    viewport={{ once: true }}
                >
                    <VStack
                        flex={1}
                        spacing='1.5rem'
                        align='start'
                    >
                        <Text
                            fontWeight='bold'
                            color='#45A29E'
                            fontSize='2rem'

                            w='100%'
                            textAlign={{
                                base: 'center',
                                md: 'start'
                            }}
                        >
                            {currentLanguage === 'English' ? 'Strategy Formation' : 'FORMIRANJE STRATEGIJE'}
                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            textAlign='justify'
                        >
                            {currentLanguage === 'English' ? (
                                `Are you looking for help evaluating an investment or getting a clearer picture of what awaits you? As an experienced team of investment analysts, risk managers, promotion and sales experts with many years of experience, we help you in the analysis (realistic analysis) of your plans, which include the analysis of product possibilities, the analysis of the necessary finances, their availability or the possibility of increasing capital by introducing new investors, the analysis of existing or new market.
                                Based on our experience, we give you the refinement of the basic idea, we suggest additional profit niches that we see, thus making the planned project a quality basis for investment.
                                Based on your input, our team will prepare the following documents for the investment/project:`

                            ) : (
                                `Tražite li pomoć za procjenu investicije ili dobivanje što jasnije slike što Vas čeka? Kao iskusan tim investicijskih analitičara, risk menadžera, promocijskih  i prodajnih stručnjaka s dugogodišnjim iskustvima, pomažemo Vam u analizi (realnoj analizi) Vaših planova koji uključuju analizu mogućnosti proizvoda, analizu potrebnih financija, dostupnosti istih ili mogućnost povećanja kapitala uvođenjem novih ulagača, analizu postojećeg ili novog tržišta. 

                                Temeljem naših iskustava dajemo Vam i doradu bazne ideje, predlažemo dodatne profitne niše koje uočimo, na taj način čineći planirani projekt kvalitetnom bazom za investiciju.
                                
                                Na temelju Vaših inputa naš tim će za investiciju/projekt pripremiti sljedeće dokumente:
                                `
                            )}

                            <Text marginTop='1rem' fontWeight='bold'>
                                {currentLanguage === 'English' ? '• Draft investment project' : '• Draft investicijskog projekta'}

                            </Text>
                            <Text fontWeight='bold'>
                                {currentLanguage === 'English' ? '• Teaser' : '• Teaser'}


                            </Text>
                            <Text fontWeight='bold'>
                                {currentLanguage === 'English' ? '• Presentation folder or White paper' : '• Prezentacijsku mapu ili White paper'}

                            </Text>
                            <Text marginBottom='1rem' fontWeight='bold'>
                                {currentLanguage === 'English' ? '• Prefeasibility study, and finally Feasibility study' : '• Prefeasibility studiju, i finalno i Feasibility studiju'}
                            </Text>

                            <Text marginBottom='1rem'>
                                {currentLanguage === 'English' ? '- Manage the project from the front page of the draft until the conclusion of the last contract, which, if we are all successful in the process, is usually the contract for the first sale.' : '- Voditi projekta od naslovne stranice drafta sve do zaključivanja zadnjeg ugovora, a taj je, ako svi budemo uspješni u procesu, obično ugovor o prvoj prodaji.'}
                            </Text>

                            <Text marginBottom='1rem'>
                                {currentLanguage === 'English' ? '- Act in the part of the project for which you estimate that you need professional representation/conduct initial negotiations with investors, organize production, create sales and marketing strategies, design products, etc., research logistics and sales channels, and, of course, financing. We work with your team as an "in-house" consultant in managing the entire investment process and negotiations with investors.' : '- Zastupati u dijelu projekta za koji procjenite da Vam treba stručno zastupanje/voditi početne pregovore s investitorima, organizirati proizvodnju, izraditi prodajne i marketing strategije, oblikovati proizvode i slično., istražiti logističke i prodajne kanale, te, naravno, financiranje. Sudjelujemo s Vašim timom kao „in house“ savjetnik u vođenju cjelokupnog procesa investicije i pregovorima s investitorima.'}
                            </Text>
                            <Text>
                                {currentLanguage === 'English' ? `If you wish, we are at your disposal in the continuation of the project's life cycle as support in conquering the market, commercializing the product/service, opening logistics and sales channels, and monitoring the realization.` : '- Ako poželite, stojimo Vam na raspolaganju i u nastavku životnog ciklusa projekta kao podrška u osvajanju tržišta, komercijalizaciji proizvoda/usluge, otvaranju logistničkih i prodajnih kanala te praćenju realizacije.'}
                            </Text>
                        </Text>
                    </VStack>
                    <Flex
                        flex={1}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Image src={StrategyFormation} w='50%' />
                    </Flex>
                </Flex>

                <Flex
                    maxW='65rem'
                    bg='#F0F0F0'
                    boxShadow='1px 2px 20px rgba(0, 0, 0, .2)'
                    borderRadius='.5rem'

                    p={{
                        base: '1rem',
                        md: '2rem'
                    }}
                    gap={{
                        base: '2rem',
                        md: '0'
                    }}
                    flexDir={{
                        base: 'column-reverse',
                        md: 'row-reverse'
                    }}

                    as={motion.div}
                    initial={{
                        x: 20,
                        opacity: 0
                    }}
                    whileInView={{
                        x: 0,
                        opacity: 1,
                        transition: {
                            duration: .3
                        }
                    }}
                    viewport={{ once: true }}
                >
                    <VStack
                        flex={1}
                        spacing='1.5rem'
                        align='start'
                    >
                        <Text
                            fontWeight='bold'
                            color='#45A29E'
                            fontSize='2rem'

                            w='100%'
                            textAlign={{
                                base: 'center',
                                md: 'start'
                            }}
                        >
                            {currentLanguage === 'English' ? 'Risk Management' : 'UPRAVLJANJE RIZICIMA'}
                        </Text>
                        <Text
                            fontSize={{
                                base: '.9rem',
                                md: '1.1rem'
                            }}
                            textAlign='justify'
                        >
                            {currentLanguage === 'English' ? (
                                `We offer you a unique tool and methodology, as well as training for all your employees, for risk management in the entire process of product/project development, but also in the later lifelong existence of the project and the entire company. Risk management directly affects your chances of business success and reduces the possibility of failure. It is crucial that it also defines the exact level of responsibility of all persons participating in the project/process at all times. Just as the rule "Follow the money trail" is valid in financial forensics, so it is in risk management. It is possible to define exactly where the risk awaits us, how dangerous it is and what measures need to be taken/before or after the occurrence of the event which will improve the process and reduce the overall risk for the company.`

                            ) : (
                                `Upravljanje rizicima – Nudimo Vam  jedinstveni alat i metodologiju, kao i edukaciju za sve Vaše djelatnike, za upravljanje rizicima u cjelokupnom procesu razvoja proizvoda/projekta, ali i u kasnijem cjeloživotnom postojanju projekta i cjelokupne tvrtke. Upravljanja rizikom Vam direktno utječe na povećanje šanse za uspjeh poslovanja i smanjuje mogućnost neuspjeha. Ključno je da i definira točnu razinu odgovornosti svih osoba koje sudjeluju u projektu/procesu u svakom trenutku. Kao što u financijskoj forenzici vrijedi pravilo „Slijedi trag novca“, tako je i u risk menadžmentu. Savršeno točno se može definirati gdje nas čeka rizik, koliko je opasan i koje mjere je potrebno poduzeti /unaprijed ili nakon nastanka događaja/koji će unaprijediti proces i smanjiti ukupni rizik za tvrtku.`
                            )}
                        </Text>
                    </VStack>
                    <Flex
                        flex={1}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Image src={RiskManagement} w='25%' />
                    </Flex>
                </Flex>

            </Flex>
        </Flex>
    )
}
