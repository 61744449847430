import { Box, Flex, Image, Text, useMediaQuery, VStack } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { CustomButton } from '../components/CustomButton'
import { WatermarkV2 } from '../components/Watermark'
import { breakpoints } from '../miscellaneous/breakpoints'

import BAImage from '../assets/BusinessAreasImage.webp'
import LogoMask from '../assets/LogoMask.webp'
import { motion, useInView } from 'framer-motion'
import { container, item } from '../miscellaneous/motionVariants'
import { useContext } from 'react'
import { ApplicationContext } from '../context/AppContext'


export const BusinessAreasSection = () => {

    const { currentLanguage, setIsSectionInView } = useContext(ApplicationContext)

    // TRACK SCREEN SIZE TO ADJUST THE NAV APPEARANCE
    const [isSmallerThanMd] = useMediaQuery('(min-width: 48em)')

    // SET THE REF FOR THE ELEMENT TO TRACK
    const ref = useRef(null);
    const isInView = useInView(ref, {
        margin: '0px 0px -90% 0px'
    });


    // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
    useEffect(() => {
        if (isInView) {
            setIsSectionInView(true);
        }
    }, [isInView, setIsSectionInView])

    return (
        <Flex
            w={breakpoints}
            marginInline='auto'
            paddingBlock='8rem'
            justifyContent='center'
            alignItems='center'
            gap={{
                base: '8rem',
                md: '5rem'
            }}

            flexDir={{
                base: 'column-reverse',
                md: 'row'
            }}
            ref={ref}
        >
            <Flex
                justifyContent='center'
                alignItems='center'
                flex={1}
                zIndex='5'
                sx={{
                    '-webkit-mask-image': `url(${LogoMask})`,
                    'mask-image': `url(${LogoMask})`,
                    '-webkit-mask-repeat': 'no-repeat',
                    'mask-repeat': 'no-repeat',
                    '-webkit-mask-size': '70%',
                    'mask-size': '70%',
                    'mask-position': 'center'
                }}

                transform={{
                    md: 'scale(1.4)'
                }}
            >
                <Image
                    src={BAImage}
                    transform='scaleX(-1)'
                    as={motion.img}
                    whileInView={{
                        scale: 1,
                        transition: {
                            duration: 1,
                            ease: 'easeOut'
                        }
                    }}
                    initial={{
                        scale: isSmallerThanMd ? 1.4 : 1,
                    }}
                    viewport={{ once: true }}
                />
            </Flex>
            <VStack
                flex={1}
                justify='start'
                align='start'
                spacing='0'
                w='100%'

                as={motion.div}
                variants={container}
                whileInView='show'
                initial='hidden'
                viewport={{ once: true }}
            >
                <WatermarkV2 text={currentLanguage === 'English' ? 'Business Areas' : 'POSLOVNA PODRUČJA'} />
                <Text
                    fontSize='1.1rem'
                    zIndex={1}
                    w='100%'

                    as={motion.div}
                    variants={item({ x: 20 })}
                    viewport={{ once: true }}
                    textAlign='justify'
                >
                    {currentLanguage === 'English' ? (
                        ' Our main business area is product development and business connection, but we can offer certainly much more with our experience.'
                    ) : (
                        'Naša glavna djelatnost je razvoj proizvoda i poslovno povezivanje ali svakako našim iskustvom možemo ponuditi puno više.'
                    )}
                </Text>
                <Box
                    as={motion.div}
                    variants={item({ x: 20 })}
                    viewport={{ once: true }}
                >
                    <CustomButton path='/businessAreas' text={currentLanguage === 'English' ? 'Find Out More' : 'Saznaj više'} />
                </Box>
            </VStack>
        </Flex>
    )
}
